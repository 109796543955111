import React from "react";
import "./Dashboard.css";

export default function Dashboard() {
  // Sample JSON data for the graph
  const graphData = [
    { day: "יום א", sales: 120 },
    { day: "יום ב", sales: 90 },
    { day: "יום ג", sales: 150 },
    { day: "יום ד", sales: 110 },
    { day: "יום ה", sales: 180 },
    { day: "יום ו", sales: 130 },
    { day: "שבת", sales: 100 },
  ];

  // Calculate the max sales for scaling the graph bars
  const maxSales = Math.max(...graphData.map((data) => data.sales));

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1 style={{ color: "wheat" }}>לוח מחוונים</h1>
      </div>
      <div className="dashboard-content">
        <div className="dashboard-section">
          <h2>ההזמנות האחרונות</h2>
          <div className="table-container">
            <table className="orders-table">
              <thead>
                <tr>
                  <th>מספר הזמנה</th>
                  <th>שם לקוח</th>
                  <th>סטטוס הזמנה</th>
                  <th>סך הכל</th>
                  <th>הזמנה פרטים</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>001</td>
                  <td>דניאל כהן</td>
                  <td>הושלם</td>
                  <td>100 ש"ח</td>
                  <td>
                    <button className="order-detail-btn">לפרטים</button>
                  </td>
                </tr>
                <tr>
                  <td>002</td>
                  <td>שרה מזרחי</td>
                  <td>בטיפול</td>
                  <td>75 ש"ח</td>
                  <td>
                    <button className="order-detail-btn">לפרטים</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button className="navigate-orders-btn">מעבר להזמנות</button>
        </div>

        <div className="dashboard-section">
          <h2>הכנסות יומיות</h2>
          <div className="graph-container">
            <div className="graph">
              {graphData.map((data, index) => (
                <div
                  key={index}
                  className="graph-bar"
                  style={{ height: `${(data.sales / maxSales) * 100}%` }}
                >
                  <div className="graph-bar-label">{data.day}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
