import React, { useEffect, useState } from "react";
import "./Sidenav.css";
import { FaBell } from "react-icons/fa";
import { logout } from "../actions/businessAction";
import { useDispatch } from "react-redux";

export default function Sidenav({ notifications }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false); // ניהול מצב הפתיחה

  return (
    <div className="sidenav-container">
      {/* אייקון הפתיחה */}
      {!isOpen && (
        <FaBell className="toggle-icon" onClick={() => setIsOpen(true)} />
      )}

      {/* התפריט הצידי */}
      {isOpen && (
        <div className="sidenav-cnt" style={{ width: "250px" }}>
          <div className="close-btn" onClick={() => setIsOpen(false)}>
            X
          </div>
          {/* תוכן התפריט */}
          <h3>התראות</h3>
          <div className="alert-cnt">
            {notifications
              .slice()
              .reverse()
              .map((notify, index) => {
                return (
                  <div className="alert" key={index}>
                    <li>{notify.description}</li>
                    <button>בוצע</button>
                  </div>
                );
              })}
          </div>
          <button onClick={() => dispatch(logout())} className="logout">
            התנתק
          </button>
        </div>
      )}
    </div>
  );
}
