import React from "react";

export default function Meal3D() {
  return (
    <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      {/* סצנה תלת-ממדית עם A-Frame */}
      <a-scene>
        {/* הצגת המודל מהקישור */}
        <a-entity
          gltf-model="https://firebasestorage.googleapis.com/v0/b/mealy-c7f64.appspot.com/o/uploads_files_2465920_burger_merged.glb?alt=media&token=4a5e62d7-f637-4725-b149-b341c975d8a7"
          position="0 0 -3"
          scale="0.5 0.5 0.5"
          rotation="0 45 0"
        ></a-entity>

        {/* תאורה לסצנה */}
        <a-light type="directional" position="2 4 -3"></a-light>

        {/* מצלמה שנמצאת במיקום קבוע */}
        <a-camera position="0 1.6 4"></a-camera>
      </a-scene>
    </div>
  );
}
