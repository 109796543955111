import React from "react";
import iconv from "iconv-lite";
import EscPosEncoder from "esc-pos-encoder";

const PrintReceipt = async () => {
  try {
    const receiptText = "תודה שקינתם אצלנו";

    // המרת הטקסט לקידוד המדפסת (ISO-8859-8)
    const encodedText = iconv.encode(receiptText, "ISO-8859-8");

    const printer = new EscPosEncoder();

    const data = printer
      .initialize()
      .text("תודה שקניתם אצלנו")
      .newline(6)
      .cut() // חיתוך הנייר
      .encode(); // קידוד המידע לפורמט מתאים

    // הוספת פקודות ESC/POS
    const escPosCommands = Uint8Array.from([
      0x40, // Initialize Printer

      ...encodedText, // הטקסט עצמו

      0x02, // Feed 2 lines
      0x02, // Feed 2 lines
      0x02, // Feed 2 lines
      0x1d,
      0x56,
      0x00, // Cut Paper
    ]);
    console.log("עברית:", escPosCommands);
    console.log("data", data);

    // פתיחת חיבור WebSocket
    const socket = new WebSocket("ws://10.0.0.120:8080");

    socket.onopen = () => {
      console.log("WebSocket מחובר!");
      socket.send(data); // שליחת הפקודות למדפסת
      socket.close();
    };

    socket.onerror = (error) => {
      console.error("שגיאת WebSocket:", error);
      alert("שגיאה בהדפסה.");
    };
  } catch (error) {
    console.error("שגיאה כללית:", error);
    alert("שגיאה בהדפסה.");
  }
};

const App = () => (
  <div>
    <button onClick={PrintReceipt}>הדפס קבלה</button>
  </div>
);

export default App;
