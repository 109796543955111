import React, { useEffect, useState } from "react";
import "./MenuProManager.css";
import Meal from "../components/Meal";
import Footer from "../components/Footer";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { BsCheckCircleFill, BsPlus, BsPlusCircleFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { businessInfo, editMenuAction } from "../actions/businessAction";

function EditMenu() {
  const menu = useSelector((state) => state.menu);
  const { loading } = menu;

  const style = useSelector((state) => state.style);
  const { background, parentButton, font, childButton } = style;

  const dispatch = useDispatch();

  const [updatedMenu, setUpdatedMenu] = useState(menu);

  const [categoryIndex, setCategoryIndex] = useState(0);
  const [parentCategory, setParentCategory] = useState(
    updatedMenu[categoryIndex].parentCategory
  );

  const [categoryChildIndex, setCategoryChildIndex] = useState(0);
  const [categoryChild, setCategoryChild] = useState(
    updatedMenu[categoryIndex]?.categories
      ? updatedMenu[categoryIndex]?.categories[0]?.category
      : null
  );

  const [editCategory, setEditCategory] = useState(false);
  const [editChildCategory, setEditChildCategory] = useState(false);

  const handleAvailable = () => {
    const newMenu = [...updatedMenu];
    newMenu[categoryIndex].available = !newMenu[categoryIndex].available;

    setUpdatedMenu(newMenu);
  };

  const handleChildCategoryAvailable = () => {
    const newMenu = [...updatedMenu];
    newMenu[categoryIndex].categories[categoryChildIndex].available =
      !newMenu[categoryIndex].categories[categoryChildIndex].available;

    setUpdatedMenu(newMenu);
  };

  const handleCheck = (value) => {
    const newMenu = updatedMenu;
    newMenu[categoryIndex].parentCategory = value;

    setUpdatedMenu(newMenu);
  };

  const setMealsAvailability = (index) => {
    const i = index.categoryIndex;
    const t = index.categoryChildIndex;
    const j = index.i;

    const newMenu = [...updatedMenu];

    if (t !== null) {
      newMenu[i].categories[t].meals[j].available =
        !newMenu[i].categories[t].meals[j].available;
    } else {
      newMenu[i].meals[j].available = !newMenu[i].meals[j].available;
    }

    setUpdatedMenu(newMenu);
  };

  const setEditMeal = (meal, index) => {
    const newMenu = updatedMenu;
    const i = index.categoryIndex;
    const t = index.categoryChildIndex;
    const j = index.i;

    if (t !== null) {
      newMenu[i].categories[t].meals[j] = meal;
    } else {
      newMenu[i].meals[j] = meal;
    }
    setUpdatedMenu(newMenu);
  };

  const handleAddMeal = () => {
    // Create a new copy of the updatedMenu
    const newMenu = [...updatedMenu];

    // Create a new meal object
    const newMeal = { name: "", price: "", desc: "", available: true };

    console.log(newMeal);

    // Push the new meal into the correct category
    if (hasCategories) {
      newMenu[categoryIndex].categories[categoryChildIndex].meals.push(newMeal);
    } else {
      newMenu[categoryIndex].meals.push(newMeal);
    }

    // Update the state with the new menu
    setUpdatedMenu(newMenu);
  };

  const hasCategories = updatedMenu[categoryIndex]?.categories;
  return (
    <div
      className="editmenu"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div className="meals-manager-cnt">
        <select
          className="parent-category-menu"
          onChange={(e) => {
            setCategoryIndex(e.target.value);
            setCategoryChildIndex(0);
            setEditChildCategory(false);
            setEditCategory(false);
            setParentCategory(menu[e.target.value].parentCategory);
            const newCategoryChild =
              menu[e.target.value]?.categories?.[0]?.category || 0;
            setCategoryChild(newCategoryChild);
          }}
        >
          {menu.map((obj, i) => (
            <option value={i} key={i}>
              {obj.parentCategory}
            </option>
          ))}
        </select>
        <FaEdit
          style={{ alignSelf: "center" }}
          onClick={() => setEditCategory(!editCategory)}
        />
      </div>
      {editCategory ? (
        <div style={{ display: "flex", marginTop: "10px" }}>
          <input
            className="input-style"
            value={parentCategory}
            onChange={(e) => setParentCategory(e.target.value)}
          />
          <div className="edit-buttons-ctn">
            {menu[categoryIndex].available ? (
              <IoEye onClick={handleAvailable} />
            ) : (
              <IoEyeOff onClick={handleAvailable} />
            )}
            <BsCheckCircleFill onClick={() => handleCheck(parentCategory)} />
          </div>
        </div>
      ) : (
        ""
      )}
      {hasCategories ? (
        <div className="meals-manager-cnt">
          <select
            className="parent-category-menu"
            value={categoryChildIndex}
            onChange={(e) => {
              setCategoryChildIndex(e.target.value);
              setCategoryChild(
                menu[categoryIndex].categories[e.target.value].category
              );
              // Handle selection change
            }}
          >
            {menu[categoryIndex].categories.map((obj, i) => (
              <option value={i} key={i}>
                {obj.category}
              </option>
            ))}
          </select>
          <FaEdit
            style={{ alignSelf: "center" }}
            onClick={() => setEditChildCategory(!editChildCategory)}
          />
        </div>
      ) : (
        ""
      )}

      {editChildCategory ? (
        <div style={{ display: "flex", marginTop: "10px" }}>
          <input
            className="input-style"
            value={categoryChild}
            onChange={(e) => setCategoryChild(e.target.value)}
          />
          <div className="edit-buttons-ctn">
            {menu[categoryIndex].categories[categoryChildIndex].available ? (
              <IoEye onClick={handleChildCategoryAvailable} />
            ) : (
              <IoEyeOff onClick={handleChildCategoryAvailable} />
            )}
            <BsCheckCircleFill />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="meals-cnt-edit-menu">
        {hasCategories
          ? updatedMenu[categoryIndex]?.categories?.[
              categoryChildIndex
            ]?.meals?.map((obj, i) => (
              <Meal
                meal={obj}
                key={obj.name}
                style={style.meal.style}
                auth={"manager"}
                index={{ categoryIndex, categoryChildIndex, i }}
                available={setMealsAvailability}
                setMeal={setEditMeal}
              />
            ))
          : updatedMenu[categoryIndex]?.meals?.map((obj, i) => (
              <Meal
                meal={obj}
                key={obj.name}
                style={style.meal.style}
                auth={"manager"}
                index={{ categoryIndex, categoryChildIndex: null, i }}
                available={setMealsAvailability}
                setMeal={setEditMeal}
              />
            ))}
      </div>
      <div
        style={{
          display: "flex",
          padding: "12px",
          width: "100%",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={handleAddMeal}
      >
        <BsPlusCircleFill style={{ marginLeft: "10px" }} />
        <h4>הוספת מנה</h4>
      </div>
      <button
        onClick={() => dispatch(editMenuAction(updatedMenu))}
        style={{ ...style.childButton.style, margin: " 20px 0" }}
      >
        אישור
      </button>
    </div>
  );
}

export default EditMenu;
