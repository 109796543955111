import React, { useState } from "react";
import "./CartMenu.css";
import { BsFillXCircleFill } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./PurchaseMenu.css";

function PurchaseMenu() {
  const navigate = useNavigate();
  const table = useSelector((state) => state.table);

  const [isOpen, setIsOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [purchase, setPurchse] = useState([]);

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const handlePayBill = (e, order) => {
    if (e.target.checked) {
      setTotalPrice(totalPrice + order.price * order.quantity);
      setPurchse([...purchase, order]); // Avoid using `push` as it mutates the state directly
    } else {
      setTotalPrice(totalPrice - order.price * order.quantity);
      setPurchse(
        purchase.filter(
          (item) => !(item.name === order.name && item.price === order.price)
        )
      );
    }
  };

  const handlePurchase = () => {
    localStorage.setItem("purchase", JSON.stringify(purchase));
    navigate(`/purchase/${table.id}`);
  };

  return (
    <div className="navigate-btn-cnt">
      <button className="cart-button">
        <FaFileInvoiceDollar
          onClick={() => setIsOpen(true)}
          style={{ height: "30px", width: "30px" }}
        />
        <span>חשבון</span>
      </button>

      {isOpen && (
        <>
          <div className="overlay" onClick={handleOverlayClick}></div>
          <div
            className="cart-menu"
            style={{ backgroundColor: "#272727", maxHeight: "90%" }}
          >
            <div className="cart-content">
              <div className="cart-item">
                <h2 style={{ color: "wheat" }}>חשבון</h2>
                <BsFillXCircleFill
                  className="close-btn"
                  onClick={handleOverlayClick}
                />
              </div>
              {table?.orders?.length > 0
                ? table.orders.map((orderInfo, index) => {
                    return (
                      <div
                        className="waiter-modal-orders"
                        key={index}
                        style={{ color: "wheat" }}
                      >
                        <h4 style={{ fontSize: "20px", marginBottom: "10px" }}>
                          המזמין: {orderInfo.name}
                        </h4>
                        {orderInfo.order.map((order, idx) => (
                          <div className="order-details" key={idx}>
                            <div style={{ marginLeft: "15px" }}>
                              {/* <input
                                type="checkbox"
                                style={{ height: "17px", width: "20px" }}
                                onClick={(e) => handlePayBill(e, order)}
                              /> */}
                            </div>
                            <div>
                              <h5>{order.name}</h5>
                              {order.quantity > 1 ? (
                                <p>כמות: {order.quantity}</p>
                              ) : (
                                ""
                              )}
                              <div>
                                {Object.entries(order.options).map(
                                  ([key, value], i) => (
                                    <div key={i} className="order-option">
                                      {/* <strong>{key}: </strong> */}
                                      {Array.isArray(value) ? (
                                        <div>
                                          {value.map((item, j) => (
                                            <li key={j}>{item}</li>
                                          ))}
                                        </div>
                                      ) : (
                                        <li>{value}</li>
                                      )}
                                    </div>
                                  )
                                )}
                                <p>מחיר: {order.price * order.quantity} ₪</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })
                : "אין הזמנות עבור שולחן זה"}
            </div>
            {/* <button
              className="cart-order-button"
              onClick={() => handlePurchase()}
            >
              סה"כ {totalPrice} לתשלום
            </button> */}
          </div>
        </>
      )}
    </div>
  );
}

export default PurchaseMenu;
