import React, { useEffect, useState } from "react";
import { BsFillEyeFill, BsFillXCircleFill, BsPencilFill } from "react-icons/bs";
import "./TablesManager.css";
import { useSelector } from "react-redux";
import { socket } from "../../App";
import AddTableModal from "../AddTableModal";
import { useNavigate } from "react-router-dom";

export default function TablesManager() {
  const navigate = useNavigate();
  const tableState = useSelector((state) => state.tables);
  const business = useSelector((state) => state.business);
  const [tables, setTables] = useState(tableState);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [showOrdersModal, setShowOrdersModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [addTableModal, setAddTableModal] = useState(false);

  const handleTableClick = (table) => {
    setSelectedTable(table);
    setEditValues({
      id: table.id,
      number: table.number,
      capacity: table.capacity,
      status: table.status,
      waiter: table.waiter || { id: "", name: "" },
      orderTime: table.orderTime || "",
      totalAmount: table.totalAmount || 0,
      paid: table.paid || 0,
      paymentStatus: table.paymentStatus || "unpaid",
      notes: table.notes || "",
      allergies: table.allergies || [],
      openTime: table.openTime || "",
      orders: table.orders || [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSaveEdit = () => {
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.id === selectedTable.id ? { ...table, ...editValues } : table
      )
    );
    setIsEditing(false);
    setSelectedTable(null);
    setShowEditModal(false);
  };

  const handleViewOrders = () => {
    setShowOrdersModal(true);
  };

  const closeModal = () => {
    setShowOrdersModal(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const calculateTotalSum = (orders) => {
    let total = 0;
    if (orders.length) {
      orders.map((orderInfo) => {
        orderInfo.order.map((order) => {
          total += Number(order.price) * Number(order.quantity);
        });
      });
    }

    return total;
  };

  useEffect(() => {
    setTables(tableState);

    socket.on("tableUpdated", (updatedData) => {
      if (business.info.name == updatedData.businessName) {
        setTables(updatedData); // Update table data in frontend

        localStorage.setItem("tables", JSON.stringify(updatedData));
      }
    });

    // Disconnect from Socket.IO when not needed
    return () => {
      socket.off("tableUpdated");
    };
  }, [tableState]);

  return (
    <div className="table-manager-container">
      {addTableModal && <AddTableModal onClose={setAddTableModal} />}
      <h2 className="table-manager-title">ניהול שולחנות</h2>
      <div className="table-main-btn">
        <button onClick={() => setAddTableModal(true)}>הוספת שולחן</button>
        <button onClick={() => navigate(`/${business.info.name}/restaurant`)}>
          ניהול סקיצה
        </button>
      </div>
      <div class="table-status-section">
        <div class="table-status">
          <div class="status-box available"></div>
          <span>פנוי</span>
        </div>
        <div class="table-status">
          <div class="status-box occupied"></div>
          <span>תפוס</span>
        </div>
        <div class="table-status">
          <div class="status-box reserved"></div>
          <span>שמור</span>
        </div>
      </div>

      <div className="table-canvas-container">
        {tables.length > 0
          ? tables.map((table) => (
              <div
                key={table.id}
                className={`table-canvas-item ${
                  selectedTable?.id === table.id ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    table.status === "available"
                      ? "#00e50a"
                      : table.status === "occupied"
                      ? "#ff2b1b"
                      : table.status === "reserved"
                      ? "orange"
                      : "grey",
                }}
                onClick={() => handleTableClick(table)}
              >
                <span className="table-number">שולחן {table.id}</span>
                <span className="table-capacity">{table.capacity} סועדים</span>

                {selectedTable?.id === table.id && (
                  <>
                    <span className="table-capacity">
                      {calculateTotalSum(selectedTable.orders)} ש"ח
                    </span>
                    <div className="table-actions">
                      <BsFillEyeFill
                        className="table-action-btn"
                        onClick={() => {
                          handleViewOrders();
                        }}
                      />
                      <BsPencilFill
                        className="table-action-btn"
                        onClick={() => {
                          setIsEditing(true);
                          setShowEditModal(true);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))
          : "הוסף שולחנות לעסק"}
      </div>

      {showEditModal && selectedTable && (
        <div className="modal-overlay" onClick={closeEditModal}>
          <div
            className="modal-edit-table"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <h3>פריטי שולחן {selectedTable.number}</h3>
              <BsFillXCircleFill
                className="close-btn"
                onClick={closeEditModal}
              />
            </div>
            <div className="table-edit-fields">
              <div className="table-edit-field">
                <label>שעת פתיחת שולחן</label>
                <input
                  type="time"
                  name="openTime"
                  value={editValues.openTime}
                  onChange={handleInputChange}
                />
              </div>
              <div className="table-edit-field">
                <label>מספר שולחן:</label>
                <input
                  type="number"
                  name="number"
                  value={editValues.number}
                  onChange={handleInputChange}
                />
              </div>
              <div className="table-edit-field">
                <label>תכולת מקומות:</label>
                <input
                  type="number"
                  name="capacity"
                  value={editValues.capacity}
                  onChange={handleInputChange}
                />
              </div>
              <div className="table-edit-field">
                <label>אלרגיות:</label>
                <input
                  type="text"
                  name="allergies"
                  value={editValues.allergies}
                  onChange={handleInputChange}
                  style={{ width: "160px" }}
                />
              </div>
              <div className="table-edit-field">
                <label>סטטוס:</label>
                <select
                  name="status"
                  value={editValues.status}
                  onChange={handleInputChange}
                  className="table-edit-field-select"
                >
                  <option value="available">פנוי</option>
                  <option value="occupied">תפוס</option>
                  <option value="reserved">שמור</option>
                </select>
              </div>
              <div className="table-edit-field">
                <label>שיוך מלצר:</label>
                <select
                  name="waiter"
                  value={editValues.waiter}
                  onChange={handleInputChange}
                  className="table-edit-field-select"
                >
                  <option value="0">-מלצרים-</option>
                  <option value="1">יוחנן דהן</option>
                  <option value="2">מאיה לוי</option>
                  <option value="3">אורן כהן</option>
                  <option value="4">תמר ישראלי</option>
                  <option value="5">אלי ברוך</option>
                </select>
              </div>
              <div className="table-edit-field">
                <label>מצב תשלום:</label>
                <select
                  name="status"
                  value={editValues.status}
                  onChange={handleInputChange}
                  className="table-edit-field-select"
                >
                  <option value="unpaid">לא שולם</option>
                  <option value="paid">שולם</option>
                  <option value="partially_paid">שולם חלקית</option>
                </select>
              </div>
              <div className="table-edit-field">
                <label>הערות:</label>
                <textarea
                  name="notes"
                  value={editValues.notes}
                  onChange={handleInputChange}
                />
              </div>

              <button className="submit-table-edit" onClick={handleSaveEdit}>
                שמור
              </button>
            </div>
          </div>
        </div>
      )}

      {showOrdersModal && selectedTable && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-table-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>הזמנות לשולחן {selectedTable.number}</h3>
              <BsFillXCircleFill className="close-btn" onClick={closeModal} />
            </div>

            {selectedTable?.orders?.length > 0 ? (
              <>
                {selectedTable.orders.map((orderInfo, index) => (
                  <div
                    className="waiter-modal-orders"
                    key={index}
                    style={{ color: "wheat" }}
                  >
                    <h4 style={{ fontSize: "20px", marginBottom: "10px" }}>
                      המזמין: {orderInfo.name}
                    </h4>
                    {orderInfo.order.map((order, idx) => (
                      <div className="order-details" key={idx}>
                        <div style={{ marginLeft: "15px" }}></div>
                        <div>
                          <h5>{order.name}</h5>
                          {order.quantity > 1 ? (
                            <p>כמות: {order.quantity}</p>
                          ) : (
                            ""
                          )}
                          <div>
                            {Object.entries(order.options).map(
                              ([key, value], i) => (
                                <div key={i} className="order-option">
                                  {/* <strong>{key}: </strong> */}
                                  {Array.isArray(value) ? (
                                    <div>
                                      {value.map((item, j) => (
                                        <li key={j}>{item}</li>
                                      ))}
                                    </div>
                                  ) : (
                                    <li>{value}</li>
                                  )}
                                </div>
                              )
                            )}
                            <p>מחיר: {order.price * order.quantity} ₪</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="total-sum">
                  <h3>סה"כ:</h3>
                  <h3>{calculateTotalSum(selectedTable.orders)} ₪</h3>
                </div>
              </>
            ) : (
              <div>אין הזמנות לשולחן זה!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
