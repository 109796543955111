import React, { useState } from "react";
import "./WaiterModalOrders.css";
import { printOrders } from "../printUtils";

export default function WaiterModalOrders({ table }) {
  const [orders, setOrders] = useState([]);

  // פונקציה להוספה או הסרה של הזמנה
  const handleOrderCheck = (orderInfo, order) => {
    const orderKey = `${orderInfo.name}-${order.name}`;
    setOrders((prevOrders) => {
      // אם ההזמנה כבר קיימת, נסיר אותה
      if (prevOrders.some((o) => o.key === orderKey)) {
        return prevOrders.filter((o) => o.key !== orderKey);
      }
      // אחרת, נוסיף אותה
      return [...prevOrders, { key: orderKey, ...order, table: table.id }];
    });
  };

  return (
    <div>
      <h3>הזמנות</h3>
      {table.orders.map((orderInfo, index) => (
        <div className="waiter-modal-orders" key={index}>
          <h4 style={{ fontSize: "20px", marginBottom: "10px" }}>
            המזמין: {orderInfo.name}
          </h4>
          {orderInfo.order.map((order, idx) => (
            <div className="order-details" key={idx}>
              <div style={{ marginLeft: "15px" }}>
                <input
                  type="checkbox"
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => handleOrderCheck(orderInfo, order)}
                  checked={orders.some(
                    (o) => o.key === `${orderInfo.name}-${order.name}`
                  )}
                />
              </div>
              <div>
                <h5>{order.name}</h5>
                {order.quantity > 1 ? <p>כמות: {order.quantity}</p> : ""}
                <div>
                  {order?.options
                    ? Object.entries(order.options).map(([key, value], i) => (
                        <div key={i} className="order-option">
                          {Array.isArray(value) ? (
                            <div>
                              {value.map((item, j) => (
                                <li key={j}>{item}</li>
                              ))}
                            </div>
                          ) : (
                            <li>{value}</li>
                          )}
                        </div>
                      ))
                    : ""}
                  <p>מחיר: {order.price * order.quantity} ₪</p>
                </div>
              </div>
            </div>
          ))}
          {orderInfo.extraRequest && <li>{orderInfo.extraRequest}</li>}
        </div>
      ))}
      <button onClick={() => printOrders(orders)}>הוצאת בונים</button>
    </div>
  );
}
