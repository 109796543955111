import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStyle } from "../actions/settingsAction";
import Loader from "./Loader";

export default function StyleSettings() {
  const dispatch = useDispatch();
  const style = useSelector((state) => state.style);
  const { loading } = style;

  const [backgroundType, setBackgroundType] = useState(
    style.background.type || "color"
  );
  const [fontColor, setFontColor] = useState(style.font.color || "black");
  const [fontFamily, setFontFamily] = useState(
    style.font.fontFamily || "system-ui"
  );
  const [background, setBackground] = useState(style.background.url || "");
  const [logo, setLogo] = useState(style.logo.url || "");
  const [withButtons, setWithButtons] = useState(style.withButtons || 0);

  const handleBackgroundTypeChange = (e) => {
    setBackgroundType(e.target.value);
  };

  const fontFamilyArr = [
    "auto",
    "system-ui",
    "Open Sans",
    "Rubik",
    "Suez One",
    "Varela Round",
  ];

  const fontStyles = {
    fontFamily: fontFamily,
    color: fontColor,
    border: "1px solid black",
    padding: "20px",
    width: "80%",
    textAlign: "center",
    margin: "15px 0",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="setting-card" style={{ background: "#0000009e" }}>
      <h2 style={{ marginBottom: "20px" }}>עיצוב תפריט</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>צבע פונט:</span>
        <input
          type="color"
          value={fontColor}
          style={{ width: "50px" }}
          onChange={(e) => setFontColor(e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>סוג פונט:</span>
        <select
          value={fontFamily}
          style={{ width: "120px", height: "auto" }}
          onChange={(e) => setFontFamily(e.target.value)}
        >
          {fontFamilyArr.map((obj) => (
            <option key={obj} value={obj}>
              {obj}
            </option>
          ))}
        </select>
      </div>

      <h2 style={fontStyles}>דוגמה לסוג פונט</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
        }}
      >
        <h3 style={{ marginLeft: "10px" }}>רקע:</h3>
        <input
          type="radio"
          name="backgroundType"
          value="color"
          style={{ width: "20px" }}
          checked={backgroundType === "color"}
          onChange={handleBackgroundTypeChange}
        />
        <label style={{ marginLeft: "5px", fontWeight: 600 }} htmlFor="color">
          צבע
        </label>

        <input
          type="radio"
          name="backgroundType"
          value="image"
          style={{ width: "20px" }}
          checked={backgroundType === "image"}
          onChange={handleBackgroundTypeChange}
        />
        <label style={{ fontWeight: 600 }} htmlFor="image">
          תמונה
        </label>
      </div>

      {backgroundType === "color" && (
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span style={mb}>בחירת צבע:</span>
          <input
            type="color"
            value={background}
            style={{ width: "50px" }}
            onChange={(e) => {
              console.log(background);
              setBackground(e.target.value);
            }}
          />
        </div>
      )}

      {backgroundType === "image" && (
        <div style={{ marginBottom: "10px" }}>
          <h3 style={mb}>הוספת קישור לתמונה:</h3>
          <input
            type="url"
            placeholder="הזן כתובת URL לתמונה"
            value={background}
            onChange={(e) => setBackground(e.target.value)}
          />
        </div>
      )}

      <button
        style={{ boxShadow: "wheat 0 0 5px" }}
        onClick={() =>
          dispatch(
            editStyle({
              ...style,
              background: {
                type: backgroundType,
                url: background,
                style: "",
              },
              font: { color: fontColor, fontFamily: fontFamily },
            })
          )
        }
      >
        אישור
      </button>
    </div>
  );
}

const mb = {
  marginBottom: "10px",
};
