import React from "react";
import logo from "../uploads/logo3.png";
import "./home-footer.css";
import { useNavigate } from "react-router-dom";

function HomeFooter({ style }) {
  const navigate = useNavigate();
  return (
    <div className="home-footer-cnt" style={style}>
      <div>
        <img className="home-footer-logo" src={logo} />
      </div>
      <div className="home-footer-details" onClick={() => navigate("/landing")}>
        <span>בעל מסעדה?</span>
        <span style={{ borderBottom: "wheat solid 1px" }}>הצטרף כאן</span>
      </div>
      <button
        style={{
          background: "none",
          borderBottom: "1px solid white",
          marginTop: "5px",
        }}
      >
        הצהרת נגישות
      </button>
    </div>
  );
}

export default HomeFooter;
