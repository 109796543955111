import React, { useState } from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addTable } from "../actions/businessAction";
import Loader from "./Loader";

export default function AddTableModal({ onClose }) {
  const dispatch = useDispatch();

  const tables = useSelector((state) => state.tables);

  const [tableId, setTableId] = useState(1);
  const [capacity, setCapacity] = useState(0);

  return (
    <div className="overlay" onClick={() => onClose(false)}>
      <div
        className="modal-table-content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation(); // מניעת סגירה אם לוחצים בתוך תוכן המודל
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            margin: "15px 0",
          }}
        >
          <h3>הוספת שולחן</h3>
          <BsFillXCircleFill
            className="close-btn"
            onClick={(e) => {
              e.stopPropagation(); // מניעת התפשטות של האירוע ל-overlay
              onClose(false); // סגירת המודל
            }}
          />
        </div>
        {tables?.loading ? (
          <Loader cnt={{ height: "120px", width: "120px" }} />
        ) : (
          <>
            <div className="table-edit-field" style={{ width: "100%" }}>
              <label>מספר שולחן:</label>
              <input
                value={tableId}
                onChange={(e) => setTableId(Math.max(0, e.target.value))}
                min={1}
                type="number"
                style={{ color: "wheat", fontWeight: "bold" }}
              />
            </div>
            <div className="table-edit-field" style={{ width: "100%" }}>
              <label>כמות אנשים:</label>
              <input
                value={capacity}
                onChange={(e) => setCapacity(Math.max(0, e.target.value))}
                min={0}
                type="number"
                style={{ color: "wheat", fontWeight: "bold" }}
              />
            </div>
            <button
              style={{ width: "100%", height: "40px", color: "wheat" }}
              onClick={() => dispatch(addTable(tableId, capacity))}
            >
              הוסף
            </button>
          </>
        )}
      </div>
    </div>
  );
}
