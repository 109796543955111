import React, { useState, useEffect } from "react";
import "./Hostess.css";
import { useDispatch, useSelector } from "react-redux";
import HostessTableModal from "../components/HostessTableModal"; // Import your modal component here
import Loader from "../components/Loader";
import { socket } from "../App";
import { MdLightMode, MdOutlineDarkMode } from "react-icons/md";
import RestaurantTable from "../components/RestaurantTable";
import { getTables, getWaiters, getWorkers } from "../actions/businessAction";
import { useParams } from "react-router-dom";

export default function Hostess() {
  const dispatch = useDispatch();
  const tablesState = useSelector((state) => state.tables); // Fetch tables directly from Redux store
  const { token } = useSelector((state) => state.worker); // Fetch tables directly from Redux store
  const [tables, setTables] = useState(tablesState);
  const [selectedTable, setSelectedTable] = useState(null); // State to store selected table for modal
  const [darkMode, setDarkMode] = useState(true);

  const { business } = useParams;

  // Function to open modal with selected table
  const handleTableClick = (table) => {
    console.log(table);

    setSelectedTable(table);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedTable(null);
  };

  useEffect(() => {
    dispatch(getTables(token));
    dispatch(getWorkers(token));

    setTables(tablesState);

    socket.on("tableUpdated", (updatedData) => {
      if (business == updatedData.businessName) {
        setTables(updatedData); // Update table data in frontend
      }
    });

    // Disconnect from Socket.IO when not needed
    return () => {
      socket.off("tableUpdated");
    };
  }, []);

  if (tablesState.loading) {
    return <Loader />;
  }

  return (
    <div className="restaurant-bg">
      {tables.length > 0
        ? tables.map((table) => (
            <RestaurantTable
              table={table}
              key={table.id}
              onClick={setSelectedTable}
            />
          ))
        : "הוסף שולחנות לעסק"}

      {selectedTable && (
        <HostessTableModal table={selectedTable} onClose={closeModal} />
      )}
    </div>
  );
}
