import React, { useEffect, useState } from "react";
import { login, registerBusiness } from "../actions/businessAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import blueVelvet from "../uploads/dark-wood.jpg";
import BackgroundImage from "../components/BackgroundImage";
import "./login.css";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState();

  const business = useSelector((state) => state.business);
  const { info } = business;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(registerBusiness(email, name, password));
  };

  useEffect(() => {
    if (info && info.token) {
      navigate("/settings");
    }
  }, [navigate, info]);

  return (
    <div className="login-cnt">
      <BackgroundImage img={blueVelvet} />
      <Logo />
      <div className="login-tab">
        <h1>הרשמה לעסק חדש</h1>
        <h2>שם מספר טלפון או מייל</h2>
        <input
          placeholder="הזן כאן"
          onChange={(e) => setEmail(e.target.value)}
        />
        <h2>שם העסק</h2>

        <input
          placeholder="שם העסק"
          onChange={(e) => setName(e.target.value)}
        />
        <h2>סיסמה</h2>
        <input
          type="password"
          placeholder="הזן כאן"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          style={{
            width: "100px",
            height: "30px",
            marginTop: "15px",
            boxShadow: "white 0 0 3px",
          }}
          onClick={(e) => submitHandler(e)}
        >
          הירשם!
        </button>
      </div>
      <button style={{ marginTop: "30px" }} onClick={() => navigate("/login")}>
        משתמש רשום לחץ כאן
      </button>
    </div>
  );
}
