import React, { useState } from "react";
import "./HostessTableModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  hostessUpdateTable,
  waiterAssignment,
} from "../actions/businessAction";

export default function HostessTableModal({ table, onClose }) {
  const dispatch = useDispatch();
  const workers = useSelector((state) => state.workers);

  const [updatedTable, setUpdatedTable] = useState({
    ...table, // Initialize with the current table's values
  });

  const handleSave = () => {
    dispatch(hostessUpdateTable(updatedTable));
    onClose(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUpdatedTable({
      ...updatedTable,
      [id]: value, // Update the value based on the field
    });
  };

  const handleChangeWaiter = (e) => {
    const name = e.target.value;

    setUpdatedTable((prev) => ({
      ...prev,
      waiter: name,
    }));
    dispatch(waiterAssignment(table.id, name));
    onClose(); // Close the modal after saving
  };

  return (
    <div className="hostess-table-modal-overlay" onClick={onClose}>
      <div className="hostess-table-modal" onClick={(e) => e.stopPropagation()}>
        <h2>פרטי שולחן {updatedTable.id}</h2>
        <label htmlFor="status">
          כמות סועדים מקסימלית: {updatedTable.capacity}
        </label>

        <div className="table-details">
          <label htmlFor="guests">כמות סועדים:</label>
          <input
            id="guestsCount"
            type="number"
            value={updatedTable.guestsCount}
            onChange={handleInputChange}
            rows="3"
          />
          {/* Status Dropdown */}
          <label htmlFor="status">סטטוס שולחן:</label>
          <select
            id="status"
            value={updatedTable.status}
            onChange={handleInputChange}
            style={{ height: "45px" }}
          >
            <option value="available">פנוי</option>
            <option value="occupied">תפוס</option>
            <option value="reserved">שמור</option>
          </select>
          <div className="allergies-section">
            <label>אלרגיות?</label>
            <textarea
              id="allergies"
              value={updatedTable.allergies || ""}
              onChange={handleInputChange}
              style={{ height: "45px" }}
            />
          </div>
          <div className="allergies-section">
            <label>שיוך למלצר</label>
            <select
              id="waiter"
              value={table.waiter}
              onChange={handleChangeWaiter}
              style={{ height: "45px" }}
            >
              <option value={""}></option>

              {workers
                .filter((worker) => worker.role === "waiter")
                .map((worker) => {
                  return (
                    <option key={worker.name} value={worker.name}>
                      {worker.name}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* Notes Section */}
          <label htmlFor="notes">הערות:</label>
          <textarea
            id="notes"
            value={updatedTable.notes || "אין הערות"}
            onChange={handleInputChange}
            rows="3"
            style={{ height: "45px" }}
          />
        </div>

        <button onClick={handleSave}>עדכון שולחן</button>
      </div>
    </div>
  );
}
