import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SettingsPro from "./screens/SettingsPro";
import Login from "./screens/LoginScreen";
import RegisterBusinessScreen from "./screens/RegisterBusinessScreen";
import CoffeeDizengoff from "./screens/CoffeeDizengoff";
import Trail from "./screens/Trail";
import Loading from "./screens/Loading";
import Menu from "./screens/Menu";
import Settings from "./screens/Settings";
import Accessibility from "./components/Accessibility";
import Alma from "./screens/alma/Alma";
import AlmaPhone from "./screens/alma/AlmaPhone";
import RestaurantHome from "./screens/ResturentHome";
import Menu2 from "./screens/Menu2";
import Menu3 from "./screens/Menu3";
import Landing from "./screens/Loading";
import Checkout from "./screens/Checkout";
import BusinessLogin from "./screens/BusinessLogin";
import Hostess from "./screens/Hostess";
import io from "socket.io-client";
import Waiter from "./screens/Waiter";
import OrderSuccess from "./screens/OrderSuccess";
import Purchase from "./screens/Purchase";
import Restaurant from "./screens/Restaurant";
import Prints from "./screens/Prints";
import Meal3D from "./screens/Meal3D";
import Examples from "./screens/Examples";

function App() {
  return (
    <div className="App">
      <Router>
        <Accessibility />
        <main>
          <Routes>
            <Route
              path="/register-business"
              element={<RegisterBusinessScreen />}
            />
            <Route path="/settings" element={<SettingsPro />} />
            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/:name/menu" element={<Menu />} />
            <Route path={`/coffee-caramel`} element={<RestaurantHome />} />
            <Route path={`/:name/menu2`} element={<Menu2 />} />
            <Route path={`/:name/menu3`} element={<Menu3 />} />
            <Route path={`/coffee`} element={<CoffeeDizengoff />} />
            <Route path="/login" element={<Login />} />
            <Route path="/:business/login" element={<BusinessLogin />} />
            <Route path="/trail" element={<Trail />} />
            <Route path="/examples" element={<Examples />} />
            <Route path="/:business/restaurant" element={<Restaurant />} />
            <Route path="/alma" element={<Alma />} />
            <Route path="/purchase/" element={<Purchase />} />
            <Route path="/purchase/:id" element={<Purchase />} />
            <Route path="/alma/phone" element={<AlmaPhone />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/meal3d" element={<Meal3D />} />
            <Route path="/checkout/:id" element={<Checkout />} />
            <Route path="/:id/success" element={<OrderSuccess />} />
            <Route path="/:business/hostess" element={<Hostess />} />
            <Route path="/:business/waiter" element={<Waiter />} />
            <Route path="/checkout/:tableNumber" element={<Checkout />} />
            <Route path="/print" element={<Prints />} />
            <Route path="/" element={<HomeScreen />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;

let socketUrl;

// בודק אם אתה בסביבת פיתוח (localhost) או בענן
if (window.location.hostname === "localhost") {
  socketUrl = process.env.REACT_APP_BASE_URL; // בכתובת של ה-localhost
} else {
  socketUrl = "https://www.app.mealy.co.il"; // בכתובת של השרת בענן
}

// מתחבר לסוקט
const socket = io(socketUrl);

// מייצא את הלקוח כדי שתוכל להשתמש בו בקבצים אחרים אם צריך
export { socket };
