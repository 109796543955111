import iconv from "iconv-lite";
import EscPosEncoder from "esc-pos-encoder";

// פונקציה להדפסת הזמנות
export const printOrders = async (orders) => {
  try {
    const encoder = new EscPosEncoder();
    let printData = encoder.initialize();

    // יצירת טקסט להדפסה
    orders.forEach((order) => {
      const text =
        `שולחן: ${order.table}\n` +
        `הזמנה: ${order.name}\n` +
        `כמות: ${order.quantity}\n` +
        `מחיר: ${order.price} ₪\n`;
      printData = printData.text(iconv.encode(text, "ISO-8859-8")).newline(2);
    });

    printData = printData.cut().encode();

    // שליחת הפקודות למדפסת באמצעות WebSocket
    const socket = new WebSocket("ws://10.0.0.120:8080");

    socket.onopen = () => {
      console.log("WebSocket מחובר!");
      socket.send(printData);
      socket.close();
    };

    socket.onerror = (error) => {
      console.error("שגיאת WebSocket:", error);
      alert("שגיאה בשליחת הדפסה.");
    };
  } catch (error) {
    console.error("שגיאה כללית:", error);
    alert("שגיאה כללית בהדפסה.");
  }
};
