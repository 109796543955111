import React, { useEffect, useState } from "react";
import "./menu2.css";
import { useDispatch, useSelector } from "react-redux";
import Meal from "../components/Meal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getMenu, getTable } from "../actions/userActions";
import Loader from "../components/Loader";
import { FaHome } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import HomeFooter from "../components/HomeFooter";
import MealOrder from "../components/MealOrder";
import CartsMenu from "../components/CartsMenu";
import UserAuth from "../components/UserAuth";
import { socket } from "../App";
import WaiterMenu from "../components/WaiterMenu";
import BillMenu from "../components/PurchaseMenu";
import LanguageMenu from "../components/LanguageMenu";
import { TABLE_GET_SUCCESS } from "../constants/tablesConstant";
import {
  USER_ACCEPT_TABLE_FAIL,
  USER_ACCEPT_TABLE_SUCCESS,
} from "../constants/userConstant";
import PurchaseMenu from "../components/PurchaseMenu";
import { BUSINESS_POST_MENU_SUCCESS } from "../constants/businessConstant";
import BackgroundImage from "../components/BackgroundImage";

// Helper function to get query parameters
const useQuery = () => new URLSearchParams(useLocation().search);

const Menu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useParams();
  const query = useQuery();
  const tableId = query.get("tableId");

  const menu = useSelector((state) => state.menu);
  const business = useSelector((state) => state.business);
  const style = useSelector((state) => state.style);
  const userState = useSelector((state) => state.user);

  const [user, setUser] = useState(userState || "");
  const [table, setTable] = useState(tableId || 0);

  const [parentIndex, setParentIndex] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [menuType, setMenuType] = useState("1");

  useEffect(() => {
    localStorage.setItem("businessName", name);
    dispatch(getMenu(name));

    if (userState?.auth) {
      dispatch(getTable());
    }
    socket.on("businessGenerateUserToken", (res) => {
      if (res.business === name) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (res.user.name === user.name && res.user.phone === user.phone) {
          localStorage.setItem("user", JSON.stringify(res.user));
          localStorage.setItem("table", JSON.stringify(res.table));

          dispatch({ type: USER_ACCEPT_TABLE_SUCCESS, payload: res.user });
          dispatch({ type: TABLE_GET_SUCCESS, payload: res.table });

          setTable(res.table);
          setUser(res.user);
        }
      }
    });

    socket.on("updateMenu", (res) => {
      if (res.businessName === name) {
        dispatch({ type: BUSINESS_POST_MENU_SUCCESS, payload: res.menu });
      }
    });
  }, [name, dispatch, user]);

  if (!menu || !style) {
    return <Loader />;
  }

  const { logo, background, parentButton, childButton, meal, font } = style;

  const filterMenu = (menu) => {
    return menu
      .filter((parentCategory) => parentCategory.available) // Filter unavailable parent categories
      .map((parentCategory) => {
        if (parentCategory.categories) {
          return {
            ...parentCategory,
            categories: parentCategory.categories
              .filter((category) => category.available) // Filter unavailable child categories
              .map((category) => ({
                ...category,
                meals: category.meals.filter((meal) => meal.available),
              })),
          };
        } else {
          return {
            ...parentCategory,
            meals: parentCategory.meals.filter((meal) => meal.available),
          };
        }
      });
  };

  const filteredMenu = filterMenu(menu);
  const hasCategories = filteredMenu[parentIndex]?.categories;

  return (
    <div
      className="menu-cnt"
      style={{
        background: background.type === "color" ? background.url : "none",
      }}
    >
      {background.type === "image" ? (
        <BackgroundImage img={background.url} />
      ) : (
        ""
      )}

      <FaHome
        className="home-btn"
        style={style.home}
        onClick={() => (window.location.href = `https://mealy.co.il/${name}`)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          overflow: "hidden",
          borderRadius: "20px",
        }}
      >
        <img
          src={logo.url}
          alt="Logo"
          className="menu-logo"
          style={logo.style}
        />
      </div>
      {business?.info?.name === name ? (
        <GrUserManager
          style={{ background: parentButton.style.background }}
          className="manager-btn"
          onClick={() => navigate("/settings")}
        />
      ) : (
        ""
      )}
      {tableId && (
        <h3
          style={{
            textAlign: "center",
            color: font.color,
            marginBottom: "20px",
            fontSize: "33px",
            fontFamily: "'Lora', serif",
          }}
        >
          {`- ${tableId} -`}
        </h3>
      )}

      <div className="menu-btn-cnt2">
        {filteredMenu.map((obj, i) => (
          <button
            style={parentButton.style}
            onClick={() => {
              setParentIndex(i);
              setCategoryIndex(0);
            }}
            key={i}
          >
            {obj.parentCategory}
          </button>
        ))}
      </div>
      {tableId &&
        (!userState || !("auth" in userState) || userState.auth === "0") && (
          <UserAuth tableId={tableId} business={name} />
        )}

      {hasCategories && (
        <div
          className="menu-btn-cnt2"
          style={{ marginTop: "10px", paddingRight: "10px" }}
        >
          {filteredMenu[parentIndex].categories.map((obj, i) => (
            <button
              onClick={() => setCategoryIndex(i)}
              style={childButton.style}
              key={i}
            >
              {obj.category}
            </button>
          ))}
        </div>
      )}

      <div className="meals-cnt">
        <h2
          style={{
            fontFamily: font.fontFamily,
            color: font.color,
            marginBottom: "10px",
          }}
        >
          {hasCategories
            ? filteredMenu[parentIndex]?.categories?.[categoryIndex]?.category
            : filteredMenu[parentIndex]?.parentCategory}
        </h2>
        <span
          style={{
            fontFamily: font.fontFamily,
            color: font.color,
            textAlign: "center",
            width: "90%",
            marginBottom: "10px",
          }}
        >
          {hasCategories
            ? filteredMenu[parentIndex]?.categories?.[categoryIndex]?.note
            : filteredMenu[parentIndex]?.note}
        </span>

        {hasCategories
          ? filteredMenu[parentIndex]?.categories?.[categoryIndex]?.meals?.map(
              (obj) =>
                style?.order?.display === "none" ? (
                  <Meal meal={obj} key={obj.name} style={meal.style} />
                ) : (
                  <MealOrder meal={obj} key={obj.name} style={meal.style} />
                )
            )
          : filteredMenu[parentIndex]?.meals?.map((obj) =>
              style?.order?.display === "none" ? (
                <Meal meal={obj} key={obj.name} style={meal.style} />
              ) : (
                <MealOrder meal={obj} key={obj.name} style={meal.style} />
              )
            )}
      </div>
      <div className="menu-bottom-navigate" style={style.order}>
        {/* <LanguageMenu /> */}
        <PurchaseMenu />
        <CartsMenu />
        <WaiterMenu />
      </div>
      <HomeFooter style={style.footer} />
    </div>
  );
};

export default Menu;
