import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RWebShare } from "react-web-share";
import "./RolesManager.css";
import { addNewWorker } from "../actions/businessAction";

export default function RolesManager() {
  const dispatch = useDispatch();

  const style = useSelector((state) => state.style);
  const { background, parentButton, font } = style;

  const workersState = useSelector((state) => state.workers);

  const [roles, setRoles] = useState([
    { name: "מנהל", value: "manager" },
    { name: "מלצר", value: "waiter" },
    { name: "מארחת", value: "hostess" },
    { name: "טבח", value: "שף" },
  ]);
  const [newRole, setNewRole] = useState("");
  const [newName, setNewName] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [workers, setWorkers] = useState(workersState || []);

  const [editWorkerId, setEditWorkerId] = useState(null);
  const [editedWorker, setEditedWorker] = useState({
    name: "",
    phone: "",
    password: "",
    role: "",
  });

  const handleAddRole = () => {
    if (newRole && !roles.includes(newRole)) {
      setRoles([...roles, newRole]);
      setNewRole("");
    }
  };

  const handleAddWorker = () => {
    if (newName && newPhone && newPassword && selectedRole) {
      const newWorker = {
        id: workers.length + 1, // Auto-incrementing id
        name: newName,
        phone: newPhone,
        password: newPassword,
        role: selectedRole,
      };

      dispatch(addNewWorker(newWorker));

      setNewName("");
      setNewPhone("");
      setNewPassword("");
      setSelectedRole("");
      setWorkers([...workers, newWorker]); // Add new worker to the workers list
    }
  };

  const handleEditWorker = (worker) => {
    setEditWorkerId(worker.id);
    setEditedWorker({ ...worker });
  };

  const handleSaveWorker = (id) => {
    setWorkers((prevWorkers) =>
      prevWorkers.map((worker) =>
        worker.id === id ? { ...worker, ...editedWorker } : worker
      )
    );
    setEditWorkerId(null);
    setEditedWorker({ name: "", phone: "", password: "", role: "" });
  };

  const handleShareWorker = (name, password) => {
    const shareData = {
      text: "הינה התפריט:",
      url: `https://www.app.mealy.co.il/login?name=${encodeURIComponent(
        name
      )}&password=${encodeURIComponent(password)}`,
      title: "Mealy",
    };
    return shareData;
  };

  return (
    <div
      className="roles-manager"
      style={{ fontFamily: font.fontFamily, color: font.color }}
    >
      <h1>מנהל תפקידים</h1>

      <div className="form-group">
        <label htmlFor="role-select">בחר תפקיד</label>
        <select
          id="role-select"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          style={{
            ...font,
            backgroundColor: "black",
            width: "160px",
            padding: "5px",
          }}
        >
          <option value="">--בחר תפקיד--</option>
          {roles.map((role, index) => (
            <option key={index} value={role.value}>
              {role.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="new-name">הוסף שם העובד</label>
        <input
          type="text"
          id="new-name"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="הזן כאן"
          style={{ ...font }}
          className="dark-input"
        />
      </div>

      <div className="form-group">
        <label htmlFor="new-phone">מספר טלפון של העובד</label>
        <input
          type="text"
          id="new-phone"
          value={newPhone}
          onChange={(e) => setNewPhone(e.target.value)}
          placeholder="הזן כאן"
          style={{ ...font }}
          className="dark-input"
        />
      </div>

      <div className="form-group">
        <label htmlFor="new-password">הזן סיסמה להתחברות</label>
        <input
          type="text"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="הזן כאן"
          style={{ ...font }}
          className="dark-input"
        />
      </div>

      <button
        className="add-role-button"
        onClick={handleAddWorker}
        style={{
          background: parentButton.style.background,
          color: parentButton.style.color,
        }}
      >
        הוסף עובד
      </button>

      <h2 style={{ marginTop: "20px" }}>רשימת עובדים</h2>
      {workers.length === 0 ? (
        <h3>התחל ליצור עובדים במסעדה</h3>
      ) : (
        <div className="table-container">
          <table className="workers-table">
            <thead>
              <tr>
                <th>שם</th>
                <th>תפקיד</th>
                <th>טלפון</th>
                <th>סיסמה</th>
                <th>ערוך</th>
                <th>שתף</th>
              </tr>
            </thead>
            <tbody>
              {workers
                ? workers.map((worker) => (
                    <tr key={worker.id}>
                      {editWorkerId === worker.id ? (
                        <>
                          <td>
                            <input
                              type="text"
                              value={editedWorker.name}
                              onChange={(e) =>
                                setEditedWorker({
                                  ...editedWorker,
                                  name: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <select
                              value={editedWorker.role}
                              onChange={(e) =>
                                setEditedWorker({
                                  ...editedWorker,
                                  role: e.target.value,
                                })
                              }
                            >
                              {roles.map((role, index) => (
                                <option key={index} value={role}>
                                  {role}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={editedWorker.phone}
                              onChange={(e) =>
                                setEditedWorker({
                                  ...editedWorker,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={editedWorker.password}
                              onChange={(e) =>
                                setEditedWorker({
                                  ...editedWorker,
                                  password: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <button onClick={() => handleSaveWorker(worker.id)}>
                              שמור
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{worker.name}</td>
                          <td>{worker.role}</td>
                          <td>{worker.phone}</td>
                          <td>{worker.password}</td>
                          <td>
                            <button onClick={() => handleEditWorker(worker)}>
                              ערוך
                            </button>
                          </td>
                          <td>
                            <RWebShare
                              data={handleShareWorker(
                                worker.name,
                                worker.password
                              )}
                              onClick={() =>
                                console.log("shared successfully!")
                              }
                            >
                              <button>שתף</button>
                            </RWebShare>
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                : "הוסף עובדים לעסק"}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
