import React, { useState } from "react";
import { animated, useSpring, easings } from "@react-spring/web";
import logo from "../uploads/logo3.png";
import { QRCode } from "react-qrcode-logo";

export default function Logo({ style }) {
  const [flipped, setFlipped] = useState(false); // Track flip state
  const [showQRCode, setShowQRCode] = useState(false); // Control QR visibility

  // Define the flip animation using react-spring
  const springs = useSpring({
    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
    config: {
      duration: 1000,
      easing: easings.easeOutBack,
    },
    onRest: () => {
      // After the animation completes, show the QR code
      if (flipped) {
        setShowQRCode(true);
      } else {
        setShowQRCode(false);
      }
    },
  });

  // Handle click event to toggle flip and show QR code
  const handleClick = () => {
    setFlipped((prev) => !prev);
    if (!flipped) {
      setTimeout(() => setShowQRCode(true), 500); // Delay QR appearance
    } else {
      setShowQRCode(false);
    }
  };

  return (
    <>
      <animated.div
        className="home-logo card"
        style={{ ...springs, ...style }}
        onClick={handleClick} // Toggle flip on click
      >
        {!flipped || !showQRCode ? (
          <img src={logo} alt="logo" style={{ width: "220px" }} />
        ) : (
          <QRCode
            value="mealy.co.il"
            qrStyle="dots"
            logoPaddingStyle="circle"
            eyeRadius={5}
          />
        )}
      </animated.div>
    </>
  );
}
