import "./Checkout.css";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { FaMinusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { placeOrder } from "../actions/userActions";
import Loader from "../components/Loader";
import Confetti from "react-confetti";

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    auth: authState,
    name: nameState,
    loading,
  } = useSelector((state) => state.user);
  const { id } = useParams();

  const [auth, setAuth] = useState(authState);
  const [name, setName] = useState(nameState);
  const [order, setOrder] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [extraRequest, setExtraRequest] = useState("");
  const [address, setAddress] = useState("");
  const [modal, setModal] = useState({ visible: false, message: "", type: "" });

  const handleSuccess = () => {
    setModal({
      visible: true,
      message: "הזמנתכם בוצעה בהצלחה",
      type: "success",
    });
  };

  const handleError = () => {
    setModal({
      visible: true,
      message: "קרתה טקלה... אנא הזמינו שוב",
      type: "error",
    });
  };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setOrder(storedCart);

    const total = storedCart.reduce(
      (acc, item) => acc + parseFloat(item.price) * item.quantity,
      0
    );
    setTotalAmount(total);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(placeOrder(name, auth, id, order, extraRequest))
      .then(() => {
        handleSuccess();
        localStorage.setItem("cart", JSON.stringify([]));
      })
      .catch(() => handleError());
  };

  const handleRemoveItem = (index) => {
    const updatedCart = order.filter((_, i) => i !== index);
    setOrder(updatedCart);
    const total = updatedCart.reduce(
      (acc, item) => acc + parseFloat(item.price) * item.quantity,
      0
    );
    setTotalAmount(total);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  if (loading) return <Loader />;

  return (
    <div className="checkout-container">
      {modal.visible && (
        <div
          style={{
            flexDirection: "column",
            alignItems: "center",
            color: "wheat",
          }}
          className={`modal ${modal.type}`}
        >
          {modal.type == "success" ? <Confetti /> : ""}
          <h3 style={{ marginBottom: "50px" }}>{modal.message}</h3>
          <button
            onClick={() =>
              navigate(
                `/${localStorage.getItem("businessName")}/menu3?tableId=${id}`
              )
            }
          >
            חזרה לתפריט
          </button>
        </div>
      )}
      <h1 className="checkout-title">
        {id ? `הזמנה לשולחן ${id}` : "ההזמנה שלך"}
      </h1>

      <form onSubmit={handleSubmit} className="checkout-form">
        {/* Order Summary */}
        <div className="order-summary">
          <h2 style={{ padding: "10px 15px" }}>סיכום הזמנה</h2>
          <table className="summary-table">
            <thead>
              <tr>
                <th>מוצר</th>
                <th>מחיר</th>
                <th>כמות</th>
                <th>סה"כ</th>
              </tr>
            </thead>
            <tbody>
              {order.map((item, index) => (
                <>
                  <tr key={index}>
                    <td
                      style={{
                        display: "flex",
                        paddingRight: "0",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <FaMinusCircle
                          className="remove-icon"
                          onClick={() => handleRemoveItem(index)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: "10px",
                          }}
                        />
                        <strong>{item.name}</strong>
                      </div>
                      <div>
                        {item.options
                          ? Object.keys(item.options).map((key) => {
                              const value = item.options[key];

                              return (
                                <div
                                  style={{
                                    textAlign: "right",
                                    margin: "0 10px 0 0",
                                  }}
                                  key={key}
                                >
                                  <strong style={{ fontWeight: "600" }}>
                                    {key}:
                                  </strong>
                                  {Array.isArray(value) ? (
                                    <ul>
                                      {value.map((v, index) => (
                                        <li key={index}>{v}</li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <ul>
                                      <li>{value}</li>
                                    </ul>
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.quantity * item.price}</td>
                  </tr>
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">סה"כ לתשלום</td>
                <td colSpan="2" style={{ textAlign: "left" }}>
                  {totalAmount} ₪
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        {/* Extra Request from Customer */}
        <div className="input-group">
          <label htmlFor="extraRequest">הערות / בקשות מיוחדות</label>
          <textarea
            id="extraRequest"
            value={extraRequest}
            onChange={(e) => setExtraRequest(e.target.value)}
            style={{ color: "black", backgroundColor: "white" }}
            placeholder="הוסף הערות כאן..."
          />
        </div>

        <button
          type="submit"
          className="checkout-button"
          onClick={(e) => handleSubmit(e)}
        >
          {id ? `הזמנה לשולחן ${id}` : "אישור הזמנה"}
        </button>
      </form>
    </div>
  );
}

export default Checkout;
