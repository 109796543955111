import React, { useState } from "react";
import "./EditCategory.css";
import { useDispatch, useSelector } from "react-redux";
import { GrDown, GrUp } from "react-icons/gr";
import { editMenuAction } from "../actions/businessAction";

export default function EditCategory() {
  const dispatch = useDispatch();

  const [categoryType, setCategoryType] = useState("regular");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const menu = useSelector((state) => state.menu);

  // Local state for managing the updated menu order and items
  const [updatedMenu, setUpdatedMenu] = useState(menu);

  const addCategory = (type) => {
    let newCategory = {};

    if (type === "regular") {
      // Define a regular category structure
      newCategory = {
        parentCategory: categoryName,
        meals: [],
        available: true,
      };
    } else if (type === "subCategory") {
      // Define a subcategory structure
      newCategory = {
        parentCategory: categoryName,
        categories: [
          {
            category: subCategoryName,
            meals: [],
            available: true,
          },
        ],
        available: true,
      };
    }
    console.log([...updatedMenu, newCategory]);

    // Add the new category to the updatedMenu state
    setUpdatedMenu([...updatedMenu, newCategory]);

    dispatch(editMenuAction([...updatedMenu, newCategory]));

    // Reset input fields
    setCategoryName("");
    setSubCategoryName("");
  };

  // Single function to move an item up or down
  const moveItem = (index, direction) => {
    const newMenu = [...updatedMenu];
    const swapIndex = direction === "up" ? index - 1 : index + 1;

    // Ensure the swap index is within bounds
    if (swapIndex < 0 || swapIndex >= newMenu.length) return;

    [newMenu[index], newMenu[swapIndex]] = [newMenu[swapIndex], newMenu[index]];
    setUpdatedMenu(newMenu);
  };

  const renderActiveCategory = () => {
    if (categoryType === "regular") {
      return (
        <input
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="הזן קטגוריה"
        />
      );
    } else if (categoryType === "subCategory") {
      return (
        <div className="edit-input">
          <input
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder="הזן קטגוריה ראשית"
          />
          <input
            value={subCategoryName}
            onChange={(e) => setSubCategoryName(e.target.value)}
            placeholder="הזן קטגוריה משנית"
          />
        </div>
      );
    }
  };

  return (
    <div className="edit-category-container">
      <div className="category-section">
        <h3 className="category-title">הוספת קטגוריה חדשה</h3>
        <div className="active-category">
          <div className="edit-buttons-cnt">
            <button onClick={() => setCategoryType("regular")}>
              קטגוריה רגילה
            </button>
            <button onClick={() => setCategoryType("subCategory")}>
              עם תת קטגוריה
            </button>
          </div>
          <div className="edit-input">{renderActiveCategory()}</div>
        </div>
        <button
          className="category-section-accept"
          onClick={() => addCategory(categoryType)}
        >
          הוסף
        </button>
      </div>

      <div className="category-section">
        <h3 className="category-title">עריכת מיקום קטגוריות</h3>
        <div className="place-category-cnt">
          {updatedMenu.map((obj, index) => (
            <div key={index} className="category-edit">
              <div className="arrows-category">
                <GrUp
                  className="arrow-btn"
                  onClick={() => moveItem(index, "up")}
                />
                <GrDown
                  className="arrow-btn"
                  onClick={() => moveItem(index, "down")}
                />
              </div>
              {obj.parentCategory}
            </div>
          ))}
        </div>
        <button
          className="category-section-accept"
          onClick={() => dispatch(editMenuAction(updatedMenu))}
        >
          אישור
        </button>
      </div>
    </div>
  );
}
