import React, { useState, useEffect } from "react";
import "./Hostess.css";
import Logo from "../components/Logo";
import { useDispatch, useSelector } from "react-redux";
import WaiterTableModal from "../components/WaiterTableModal"; // Import your modal component here
import Loader from "../components/Loader";
import { socket } from "../App";
import BlinkingSvg from "../components/BlinkingSvg";
import { getTables, getWorker } from "../actions/businessAction";
import { TABLES_UPDATE_SUCCESS } from "../constants/tablesConstant";
import { ToastContainer, toast } from "react-toastify";
import RestaurantTable from "../components/RestaurantTable";
import Sidenav from "../components/Sidenav";
import { useNavigate, useParams } from "react-router-dom";

export default function Waiter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tablesState = useSelector((state) => state.tables); // Fetch tables directly from Redux store
  const worker = useSelector((state) => state.worker); // Fetch tables directly from Redux store
  const [tables, setTables] = useState(tablesState);
  const [selectedTable, setSelectedTable] = useState(null);

  const { business } = useParams();

  const notifyRegular = (msg) => toast(msg);

  const notifyInfo = (msg) => toast.info(msg);

  const notifyAlert = (msg) => toast.error(msg);

  // Function to open modal with selected table
  const handleTableClick = (table) => {
    setSelectedTable(table);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedTable(null);
  };

  useEffect(() => {
    if (!worker?.token) {
      const businessName = localStorage.getItem("businessName");
      console.log(!worker?.token);

      if (businessName) {
        navigate(`/${businessName}/login`);
      } else {
        navigate(`/`);
      }
    } else {
      dispatch(getTables(worker.token));
      dispatch(getWorker());

      socket.on("tableUpdated", (updatedData) => {
        if (business == updatedData.businessName) {
          dispatch({
            type: TABLES_UPDATE_SUCCESS,
            payload: updatedData.tables,
          });
          localStorage.setItem("tables", JSON.stringify(updatedData.tables));
        }
      });

      socket.on("calltowaiter", (notify) => {
        if (notify.business == business) {
          notifyInfo(notify.description);
        }
      });

      socket.on("userAskConnect", (notify) => {
        if (notify.business == business) {
          notifyAlert(notify.description);
        }
      });

      socket.on("placeOrder", (notify) => {
        if (notify.business == business) {
          notifyRegular(notify.description);
        }
      });

      // Disconnect from Socket.IO when not needed
      return () => {
        socket.off("tableUpdated");
      };
    }
  }, [dispatch]);

  if (tablesState?.loading) {
    return <Loader />;
  }

  if (!worker || worker?.loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="restaurant-bg">
        <Sidenav notifications={worker.notifications} />
        <ToastContainer />
        {tablesState.length > 0
          ? tablesState.map((table) => (
              <RestaurantTable
                table={table}
                key={table.id}
                onClick={setSelectedTable}
              />
            ))
          : "הוסף שולחנות לעסק"}

        {/* Modal for table details */}
        {selectedTable && (
          <WaiterTableModal table={selectedTable} onClose={closeModal} />
        )}
      </div>
    </div>
  );
}
