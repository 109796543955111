import React, { useEffect, useState } from "react";
import "./Restaurant.css";
import Draggable from "react-draggable";
import { ReactComponent as TableSVG } from "../svg/tables/table.svg";
import Sidenav from "../components/Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { getTables } from "../actions/businessAction";
import { socket } from "../App";
import HostessTableModal from "../components/HostessTableModal";
import RestaurantTable from "../components/RestaurantTable";
import { useParams } from "react-router-dom";

export default function Restaurant() {
  const dispatch = useDispatch();
  const tablesState = useSelector((state) => state.tables); // Fetch tables directly from Redux store
  const { info } = useSelector((state) => state.business); // Fetch tables directly from Redux store
  const [tables, setTables] = useState(tablesState);
  const [selectedTable, setSelectedTable] = useState(null);

  const { business } = useParams;

  const closeModal = () => {
    setSelectedTable(null);
  };

  useEffect(() => {
    dispatch(getTables(info.token));

    setTables(tablesState);

    socket.on("tableUpdated", (updatedData) => {
      if (business == updatedData.businessName) {
        setTables(updatedData); // Update table data in frontend
      }
    });

    // Disconnect from Socket.IO when not needed
    return () => {
      socket.off("tableUpdated");
    };
  }, []);

  return (
    <div className="restaurant-bg">
      {tables.length > 0
        ? tables.map((table) => (
            <RestaurantTable table={table} key={table.id} isDraggable={true} /> // הוספת key אם
          ))
        : "הוסף שולחנות לעסק"}

      {/* Modal for table details */}
      {selectedTable && (
        <HostessTableModal table={selectedTable} onClose={closeModal} />
      )}
    </div>
  );
}
