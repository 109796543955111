// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDxlG6SJrMVDJX9MMt5wqUmfmm8JrpN758",
  authDomain: "mealy-c7f64.firebaseapp.com",
  projectId: "mealy-c7f64",
  storageBucket: "mealy-c7f64.appspot.com",
  messagingSenderId: "774887598916",
  appId: "1:774887598916:web:84567678ffb814f6da0511",
  measurementId: "G-ZCKPZQB0KQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
