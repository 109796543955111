export const BUSINESS_REGISTER_REQUEST = "BUSINESS_REGISTER_REQUEST";
export const BUSINESS_REGISTER_SUCCESS = "BUSINESS_REGISTER_SUCCESS";
export const BUSINESS_REGISTER_FAIL = "BUSINESS_REGISTER_FAIL";

export const BUSINESS_LOGIN_REQUEST = "BUSINESS_LOGIN_REQUEST";
export const BUSINESS_LOGIN_SUCCESS = "BUSINESS_LOGIN_SUCCESS";
export const BUSINESS_LOGIN_FAIL = "BUSINESS_LOGIN_FAIL";
export const BUSINESS_LOGOUT = "BUSINESS_LOGOUT";
export const BUSINESS_LOGOUT_FAIL = "BUSINESS_LOGOUT_FAIL";

export const BUSINESS_GET_MENU_REQUEST = "BUSINESS_GET_MENU_REQUEST";
export const BUSINESS_GET_MENU_SUCCESS = "BUSINESS_GET_MENU_SUCCESS";
export const BUSINESS_GET_MENU_FAIL = "BUSINESS_GET_MENU_FAIL";

export const BUSINESS_POST_MENU_REQUEST = "BUSINESS_POST_MENU_REQUEST";
export const BUSINESS_POST_MENU_SUCCESS = "BUSINESS_POST_MENU_SUCCESS";
export const BUSINESS_POST_MENU_FAIL = "BUSINESS_POST_MENU_FAIL";

export const BUSINESS_ADD_CATEGORY_REQUEST = "BUSINESS_ADD_CATEGORY_REQUEST";
export const BUSINESS_ADD_CATEGORY_SUCCESS = "BUSINESS_ADD_CATEGORY_SUCCESS";
export const BUSINESS_ADD_CATEGORY_FAIL = "BUSINESS_ADD_CATEGORY_FAIL";

export const BUSINESS_ADD_MEAL_REQUEST = "BUSINESS_ADD_MEAL_REQUEST";
export const BUSINESS_ADD_MEAL_SUCCESS = "BUSINESS_ADD_MEAL_SUCCESS";
export const BUSINESS_ADD_MEAL_FAIL = "BUSINESS_ADD_MEAL_FAIL";

export const BUSINESS_EDIT_MENU_REQUEST = "BUSINESS_EDIT_MENU_REQUEST";
export const BUSINESS_EDIT_MENU_SUCCESS = "BUSINESS_EDIT_MENU_SUCCESS";
export const BUSINESS_EDIT_MENU_FAIL = "BUSINESS_EDIT_MENU_FAIL";
