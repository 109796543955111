// workerConstants.js

export const WORKER_GET_LIST_REQUEST = "WORKER_GET_LIST_REQUEST";
export const WORKER_GET_LIST_SUCCESS = "WORKER_GET_LIST_SUCCESS";
export const WORKER_GET_LIST_FAIL = "WORKER_GET_LIST_FAIL";

export const WORKER_ADD_REQUEST = "WORKER_ADD_REQUEST";
export const WORKER_ADD_SUCCESS = "WORKER_ADD_SUCCESS";
export const WORKER_ADD_FAIL = "WORKER_ADD_FAIL";

export const WORKER_LOGOUT = "WORKER_LOGOUT";

export const WORKER_LOGIN_REQUEST = "WORKER_LOGIN_REQUEST";
export const WORKER_LOGIN_SUCCESS = "WORKER_LOGIN_SUCCESS";
export const WORKER_LOGIN_FAIL = "WORKER_LOGIN_FAIL";

export const GET_WORKER_REQUEST = "GET_WORKER_REQUEST";
export const GET_WORKER_SUCCESS = "GET_WORKER_SUCCESS";
export const GET_WORKER_FAIL = "GET_WORKER_FAIL";
