import React from "react";
import BackgroundImage from "../components/BackgroundImage";
import Logo from "../components/Logo";
import "./Examples.css";
import img1 from "../uploads/menu-examples/1.png";
import img2 from "../uploads/menu-examples/2.png";
import img3 from "../uploads/menu-examples/3.png";
import img5 from "../uploads/menu-examples/5.png";
import img6 from "../uploads/menu-examples/6.png";
import img7 from "../uploads/menu-examples/7.png";
import img8 from "../uploads/menu-examples/8.png";
import img9 from "../uploads/menu-examples/9.png";
import { useNavigate } from "react-router-dom";

export default function Examples() {
  const navigate = useNavigate();

  return (
    <div className="menus-example-cnt">
      <BackgroundImage />
      <Logo />
      <h2>דוגמות לתפריטים</h2>
      <div className="card-example-cnt">
        <div
          className="card-example"
          onClick={() => navigate("/gallery/menu2")}
        >
          <img src={img1} />
        </div>
        <div
          className="card-example"
          onClick={() => navigate("/el-gautzo/menu2")}
        >
          <img src={img5} />
        </div>
        <div
          className="card-example"
          onClick={() => navigate("/marakesh/menu")}
        >
          <img src={img2} />
        </div>
        <div className="card-example" onClick={() => navigate("/haokai/menu")}>
          <img src={img3} />
        </div>
        <div
          className="card-example"
          onClick={() => navigate("/lior-patissrie/menu")}
        >
          <img src={img6} />
        </div>
        <div
          className="card-example"
          onClick={() =>
            (window.location.href = "https://mealy.co.il/coffee-caramel/")
          }
        >
          <img src={img7} />
        </div>
        <div
          className="card-example"
          onClick={() =>
            (window.location.href = "https://mealy.co.il/coffee-caramel/")
          }
        >
          <img src={img8} />
        </div>
        <div
          className="card-example"
          onClick={() =>
            (window.location.href = "https://mealy.co.il/coffee-caramel/")
          }
        >
          <img src={img9} />
        </div>
      </div>
    </div>
  );
}
