import React from "react";
import "./RestaurantSettings.css";
import { logout } from "../actions/businessAction";
import { useDispatch } from "react-redux";

export default function RestaurantSettings() {
  const dispatch = useDispatch();

  return (
    <div className="settings-container">
      <div className="settings-header">
        <h1>הגדרות מסעדה</h1>
      </div>

      <div className="settings-sections">
        <div className="settings-section">
          <h2>מידע על המסעדה</h2>
          <p>שם המסעדה: קפה קרמל</p>
          <p>כתובת: רחוב הים 5, נתניה</p>
          <button className="update-button">עדכון פרטים</button>
        </div>

        <div className="settings-section">
          <h2>ניהול תפריט</h2>
          <p>ערוך את התפריט שלך כאן.</p>
          <button className="update-button">עריכת תפריט</button>
        </div>

        <div className="settings-section">
          <h2>הגדרות התראות</h2>
          <p>בחר כיצד ברצונך לקבל התראות.</p>
          <button className="update-button">עדכון התראות</button>
        </div>

        <div className="settings-section">
          <h2>ניהול משתמשים</h2>
          <p>נהל את המשתמשים המורשים בגישה למסעדה.</p>
          <button className="update-button">ניהול משתמשים</button>
        </div>
      </div>

      <div className="logout-container">
        <button className="logout-button" onClick={() => dispatch(logout())}>
          יציאה
        </button>
      </div>
    </div>
  );
}
