import React, { useState } from "react";
import "./UserAuth.css"; // Assuming you are adding the CSS in this file
import { socket } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { enterTable } from "../actions/userActions";
import Loader from "./Loader";

export default function UserAuth({ business, tableId }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const user = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("user", JSON.stringify(name, phone));
    dispatch(enterTable(name, phone, tableId, business));
  };

  return (
    <div className="overlay" style={{ zIndex: 6 }}>
      <div className="user-auth-container" onClick={(e) => e.stopPropagation()}>
        {user && user?.auth == "0" ? (
          <>
            <h3>לאחר האישור תוכלו להזמין</h3>
            <Loader
              cnt={{ height: "55px" }}
              spinner={{ width: "20px", height: "20px", marginTop: "20px" }}
            />
          </>
        ) : (
          <>
            {user && user.loading ? (
              <Loader
                cnt={{ height: "55px" }}
                spinner={{ width: "20px", height: "20px", marginTop: "20px" }}
              />
            ) : (
              <>
                <h2>התחברות</h2>
                <form onSubmit={handleSubmit}>
                  <div className="auth-input-group">
                    <label htmlFor="name">שם:</label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="auth-input-group">
                    <label htmlFor="phone">מספר טלפון:</label>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                  <button className="user-auth-accept" type="submit">
                    התחבר
                  </button>
                </form>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
