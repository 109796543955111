// tableConstants.js
export const TABLES_GET_LIST_REQUEST = "TABLES_GET_LIST_REQUEST";
export const TABLES_GET_LIST_SUCCESS = "TABLES_GET_LIST_SUCCESS";
export const TABLES_GET_LIST_FAIL = "TABLES_GET_LIST_FAIL";

export const TABLES_UPDATE_REQUEST = "TABLES_UPDATE_REQUEST";
export const TABLES_UPDATE_SUCCESS = "TABLES_UPDATE_SUCCESS";
export const TABLES_UPDATE_FAIL = "TABLES_UPDATE_FAIL";

export const TABLES_OPEN_REQUEST = "TABLES_OPEN_REQUEST";
export const TABLES_OPEN_SUCCESS = "TABLES_OPEN_SUCCESS";
export const TABLES_OPEN_FAIL = "TABLES_OPEN_FAIL";

export const TABLES_CLOSE_REQUEST = "TABLES_CLOSE_REQUEST";
export const TABLES_CLOSE_SUCCESS = "TABLES_CLOSE_SUCCESS";
export const TABLES_CLOSE_FAIL = "TABLES_CLOSE_FAIL";

export const HOSTESS_UPDATE_TABLE_REQUEST = "HOSTESS_UPDATE_TABLE_REQUEST";
export const HOSTESS_UPDATE_TABLE_SUCCESS = "HOSTESS_UPDATE_TABLE_SUCCESS";
export const HOSTESS_UPDATE_TABLE_FAIL = "HOSTESS_UPDATE_TABLE_FAIL";

export const TABLE_GET_REQUEST = "TABLE_GET_REQUEST";
export const TABLE_GET_SUCCESS = "TABLE_GET_SUCCESS";
export const TABLE_GET_FAIL = "TABLE_GET_FAIL";

export const TABLE_ADD_REQUEST = "TABLE_ADD_REQUEST";
export const TABLE_ADD_SUCCESS = "TABLE_ADD_SUCCESS";
export const TABLE_ADD_FAIL = "TABLE_ADD_FAIL";

export const MOVE_TABLE_REQUEST = "MOVE_TABLE_REQUEST";
export const MOVE_TABLE_SUCCESS = "MOVE_TABLE_SUCCESS";
export const MOVE_TABLE_FAIL = "MOVE_TABLE_FAIL";

export const ASSIGN_WAITER_REQUEST = "ASSIGN_WAITER_REQUEST";
export const ASSIGN_WAITER_SUCCESS = "ASSIGN_WAITER_SUCCESS";
export const ASSIGN_WAITER_FAIL = "ASSIGN_WAITER_FAIL";
