import React, { useEffect, useState } from "react";
import "./MenuProManager.css";
import Footer from "../components/Footer";
import EditMenu from "./EditMenu";
import EditCategory from "./EditCategory";

function MenuProManager({ style }) {
  const [type, setType] = useState("editMenu");

  const renderActiveComponent = () => {
    switch (type) {
      case "editMenu":
        return <EditMenu />;
      case "editCategory":
        return <EditCategory />;
      default:
        return <EditMenu />;
    }
  };

  return (
    <div className="menu-manager-cnt">
      <div className="button-container">
        <button
          style={{ ...style.childButton.style, margin: "0" }}
          onClick={() => setType("editMenu")}
        >
          עריכת תפריט
        </button>
        <button
          style={{ ...style.childButton.style, margin: "0" }}
          onClick={() => setType("editCategory")}
        >
          עריכת קטגוריות
        </button>
      </div>
      {renderActiveComponent()}

      <Footer />
    </div>
  );
}

export default MenuProManager;
