import React, { useState } from "react";
import "./CartMenu.css";
import { BsFillXCircleFill } from "react-icons/bs";
import { BiBell } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import svg from "../svg/waiter.svg";
import { callToWaiter } from "../actions/userActions";
import Loader from "./Loader";
import Draggable from "react-draggable";

function WaiterMenu() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [msg, setMsg] = useState(null);

  const { loading } = useSelector((state) => state.user || {});

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="navigate-btn-cnt">
      <button className="cart-button">
        <BiBell
          onClick={() => setIsOpen(true)}
          style={{ height: "30px", width: "30px" }}
        />
        <span>בקשה למלצר</span>
      </button>

      {isOpen && (
        <>
          <div className="overlay" onClick={handleOverlayClick}></div>
          <div className="cart-menu">
            <div
              className="cart-content"
              style={{ height: "300px", justifyContent: "space-between" }}
            >
              <div className="cart-item">
                <h2>קרא למלצר</h2>
                <BsFillXCircleFill
                  className="close-btn"
                  onClick={handleOverlayClick}
                />
              </div>
              {loading ? (
                <Loader />
              ) : msg ? (
                <h3 style={{ marginBottom: "150px" }}>{msg}</h3>
              ) : (
                <>
                  <img style={{ height: "150px" }} src={svg} alt="waiter" />
                  <button
                    style={{ height: "50px" }}
                    onClick={async () => {
                      try {
                        const response = dispatch(callToWaiter());
                        response.then(() => {
                          setMsg("הקריאה בוצעה בהצלחה!");
                        });
                      } catch (error) {
                        setMsg("שגיאה בקריאה למלצר");
                      }
                    }}
                  >
                    לחץ כאן לקריאה למלצר
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WaiterMenu;
