import React, { useState, useEffect } from "react";
import "./CartMenu.css";
import { BsFillXCircleFill } from "react-icons/bs";
import { FaMinusCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { BiCart } from "react-icons/bi";

const useQuery = () => new URLSearchParams(useLocation().search);

function CartsMenu() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const query = useQuery();
  const tableId = query.get("tableId");

  const toggleCart = () => {
    // Update cart items from local storage each time the cart is opened
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const updatedCart = storedCart.map((item) => ({
      ...item,
      price: parseFloat(item.price), // Convert price to number
    }));
    setCartItems(updatedCart);
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const handleRemoveItem = (index) => {
    const confirmed = window.confirm(
      "האם אתה בטוח שברצונך למחוק את הפריט מהעגלה?"
    );
    if (confirmed) {
      const updatedCart = cartItems.filter((_, i) => i !== index);
      setCartItems(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  };

  return (
    <div className="navigate-btn-cnt">
      <button className="cart-button" onClick={toggleCart}>
        <BiCart style={{ height: "30px", width: "30px" }} />
        {cartItems.length > 0 && (
          <div style={{ position: "absolute", width: "65px" }}>
            <div className="red-dot" />
          </div>
        )}
        <span>עגלה</span>
      </button>

      {isOpen && (
        <>
          <div id="cart" className="overlay" onClick={handleOverlayClick}></div>
          <div className="cart-menu">
            <div className="cart-content">
              <div className="cart-item">
                <h2>ההזמנה שלך</h2>
                <BsFillXCircleFill
                  className="close-btn"
                  onClick={handleOverlayClick}
                />
              </div>

              {/* Table for cart items */}
              {cartItems.length > 0 ? (
                <div style={{ maxHeight: "375px" }}>
                  <table className="cart-table">
                    <thead>
                      <tr>
                        <th>שם</th>
                        <th>מחיר</th>
                        <th>כמות</th>
                        <th>סכום</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item, index) => (
                        <>
                          <tr key={index}>
                            <td
                              style={{
                                display: "flex",
                                paddingRight: "0",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <FaMinusCircle
                                  className="remove-icon"
                                  onClick={() => handleRemoveItem(index)}
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    marginLeft: "10px",
                                  }}
                                />
                                <strong>{item.name}</strong>
                              </div>
                              <div>
                                {item.options
                                  ? Object.keys(item.options).map((key) => {
                                      const value = item.options[key];

                                      return (
                                        <div
                                          style={{
                                            textAlign: "right",
                                            margin: "0 10px 0 0",
                                          }}
                                          key={key}
                                        >
                                          <strong style={{ fontWeight: "600" }}>
                                            {key}:
                                          </strong>
                                          {Array.isArray(value) ? (
                                            <ul style={{ paddingLeft: 0 }}>
                                              {value.map((v, index) => (
                                                <li key={index}>{v}</li>
                                              ))}
                                            </ul>
                                          ) : (
                                            <ul style={{ paddingLeft: 0 }}>
                                              <li>{value}</li>
                                            </ul>
                                          )}
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            </td>
                            <td>{item.price}</td>
                            <td>{item.quantity}</td>
                            <td>{item.quantity * item.price}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {/* Total Calculation */}
                  <div className="cart-total">
                    <h3>סה"כ</h3>
                    <h3>{calculateTotal().toFixed(2)} </h3>
                  </div>
                </div>
              ) : (
                <strong style={{ marginTop: "15px" }}>
                  אתם לא רעבים? יאללה להתחיל להזמין!
                </strong>
              )}
            </div>
            <button
              className="cart-order-button"
              disabled={cartItems.length < 1}
              style={{ backgroundColor: cartItems.length < 1 ? "gray" : "" }}
              onClick={() => {
                if (tableId) {
                  navigate(`/checkout/${tableId}`);
                } else {
                  localStorage.setItem(
                    "purchase",
                    localStorage.getItem("cart")
                  );
                  navigate("/purchase");
                }
              }}
            >
              {tableId ? `לביצוע הזמנה לשולחן ${tableId}` : "לביצוע הזמנה"}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CartsMenu;
