import { BUSINESS_LOGOUT } from "../constants/businessConstant.js";
import {
  WORKER_GET_LIST_REQUEST,
  WORKER_GET_LIST_SUCCESS,
  WORKER_GET_LIST_FAIL,
  WORKER_ADD_REQUEST,
  WORKER_ADD_SUCCESS,
  WORKER_ADD_FAIL,
  WORKER_LOGOUT,
  WORKER_LOGIN_REQUEST,
  WORKER_LOGIN_SUCCESS,
  WORKER_LOGIN_FAIL,
  GET_WORKER_REQUEST,
  GET_WORKER_SUCCESS,
  GET_WORKER_FAIL,
} from "../constants/workerConstants.js";

export const workersReducer = (state = [], action) => {
  switch (action.type) {
    case WORKER_GET_LIST_REQUEST:
      return { loading: true };
    case WORKER_GET_LIST_SUCCESS:
      return action.payload;
    case WORKER_GET_LIST_FAIL:
      return { loading: false, error: action.payload };
    case WORKER_ADD_REQUEST:
      return { ...state, loadingAdd: true };
    case WORKER_ADD_SUCCESS:
      return action.payload;
    case WORKER_ADD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case WORKER_LOGOUT:
      return [];
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const workerReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_LOGIN_REQUEST:
      return { loading: true };
    case WORKER_LOGIN_SUCCESS:
      return {
        loading: false,
        ...action.payload,
      };
    case WORKER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_WORKER_REQUEST:
      return { loading: true };
    case GET_WORKER_SUCCESS:
      return { loading: false, ...action.payload };
    case GET_WORKER_FAIL:
      return { loading: false, error: action.payload };
    case WORKER_LOGOUT:
      return {};
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};
