import React, { useState } from "react";
import "./HostessTableModal.css";
import "./WaiterTableModal.css";
import { useDispatch } from "react-redux";
import { acceptGuest, hostessUpdateTable } from "../actions/businessAction";
import { IoMdPerson } from "react-icons/io";
import WaiterTableSettings from "./WaiterTableSettings";
import WaiterModalOrders from "./WaiterModalOrders";

export default function HostessTableModal({ table, onClose }) {
  const dispatch = useDispatch();

  const [type, setType] = useState("הגדרות שולחן");

  const [updatedTable, setUpdatedTable] = useState({
    ...table, // Initialize with the current table's values
  });

  const handleSave = () => {
    dispatch(hostessUpdateTable(updatedTable));
    onClose(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUpdatedTable({
      ...updatedTable,
      [id]: value, // Update the value based on the field
    });
  };

  const renderComponent = () => {
    switch (type) {
      case "הזמנות":
        return <WaiterModalOrders table={table} />;
      case "הגדרות שולחן":
        return <WaiterTableSettings table={table} onClose={onClose} />;

      default:
        return <WaiterTableSettings table={table} onClose={onClose} />;
    }
  };

  return (
    <div className="waiter-table-modal-overlay" onClick={onClose}>
      <div className="hostess-table-modal" onClick={(e) => e.stopPropagation()}>
        <h2>פרטי שולחן {updatedTable.id}</h2>
        <div className="waiter-modal-btn-cnt">
          <button onClick={() => setType("הזמנות")}>הזמנות</button>
          <button onClick={() => setType("הגדרת שולחן")}>הגדרות שולחן</button>
        </div>
        {table.allergies.length > 0 ? (
          <label className="waiter-modal-allergies" htmlFor="status">
            אלרגיות: {table.allergies}
          </label>
        ) : (
          ""
        )}
        {renderComponent()}
      </div>
    </div>
  );
}
