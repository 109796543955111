import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = ({ spinner, cnt }) => {
  return (
    <div
      style={{ display: "flex", overflow: "hidden", height: "99vh", ...cnt }}
    >
      <Spinner
        animation="border"
        role="status"
        style={{
          width: "100px",
          height: "100px",
          margin: "auto",
          display: "block",
          ...spinner,
        }}
      ></Spinner>
    </div>
  );
};

export default Loader;
