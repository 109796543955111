import React from "react";

export default function BarTable({ status }) {

  const tableColors = {
    available: "#68A3FF",
    occupied: "#FF6868",
    reserved: "#FFC868",
    default: "#68A3FF",
  };

  // מקבל את הצבע המתאים לסטטוס הנוכחי, או ברירת מחדל
  const tableColor = tableColors[status] || tableColors.default;

  // פונקציה שמכהה צבע ב-10%
  const darkenColor = (color, percent) => {
    const num = parseInt(color.slice(1), 16);
    const r = (num >> 16) - Math.round((percent / 100) * 255);
    const g = ((num >> 8) & 0x00ff) - Math.round((percent / 100) * 255);
    const b = (num & 0x0000ff) - Math.round((percent / 100) * 255);
    return `#${(
      (1 << 24) +
      (Math.max(r, 0) << 16) +
      (Math.max(g, 0) << 8) +
      Math.max(b, 0)
    )
      .toString(16)
      .slice(1)}`;
  };

  const chairColor = darkenColor(tableColor, 40); // צבע הכיסאות כהה ב-10%

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="300"
      viewBox="0 0 200 320"
    >
      <defs>
        <filter id="boxShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="4"
            dy="4"
            stdDeviation="5"
            floodColor="rgba(0, 0, 0, 0.5)"
          />
        </filter>
      </defs>

      <rect
        x="20"
        y="0"
        width="160"
        height="300"
        fill={tableColor}
        rx="15"
        ry="15"
        filter="url(#boxShadow)"
      />

      <rect x="183" y="15" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="183" y="85" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="183" y="155" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="183" y="225" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="0" y="15" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="0" y="85" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="0" y="155" width="17" height="60" fill={chairColor} ry="5" />
      <rect x="0" y="225" width="17" height="60" fill={chairColor} ry="5" />
    </svg>
  );
}
