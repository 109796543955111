import React, { useState } from "react";
import Draggable from "react-draggable";
import RegularTable from "./Tables/RegularTable";
import BarTable from "./Tables/BarTable";
import { useDispatch } from "react-redux";
import { moveTable } from "../actions/businessAction";

export default function RestaurantTable({ table, isDraggable, onClick }) {
  const dispatch = useDispatch();
  const renderTable = () => {
    switch (table.type) {
      case "table":
        return <RegularTable status={table.status} />;
      case "bar":
        return <BarTable status={table.status} />;
      default:
        return <RegularTable status={table.status} />;
    }
  };

  const setSelect = () => {
    onClick(table);
  };

  const handleStop = (e, data) => {
    const position = { x: data.x, y: data.y };
    dispatch(moveTable(table.id, position));
  };

  if (isDraggable) {
    return (
      <Draggable defaultPosition={table.position} onStop={handleStop}>
        <div className="table-cnt">
          {renderTable()}
          <div className="table-circle">
            <h3>{table.id}</h3>
          </div>
        </div>
      </Draggable>
    );
  }

  return (
    <div
      onClick={setSelect}
      className="table-cnt"
      style={{
        transform: `translate(${table?.position?.x || 0}px, ${
          table?.position?.y || 0
        }px)`,
      }}
    >
      {renderTable()}
      <div className="table-circle">
        <h3>{table.id}</h3>
      </div>
    </div>
  );
}
