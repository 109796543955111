import React, { useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import "./meals.css";

export default function Meal({ meal, style, auth, index, available, setMeal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [mealObj, setMealObj] = useState(meal);

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 700,
    },
  });

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEditCheck = () => {
    setEditable(false);
    //Change meal in menuPro manager
    setMeal(mealObj, index);
  };

  const renderEditButtons = () => {
    if (auth) {
      return (
        <div className="edit-cnt">
          {auth && meal.available ? (
            <IoEye onClick={() => available(index)} />
          ) : (
            <IoEyeOff onClick={() => available(index)} />
          )}
          <FaEdit onClick={() => setEditable(true)} />
          <BsCheckCircleFill onClick={handleEditCheck} />
        </div>
      );
    }
    return null;
  };

  return (
    <animated.div style={{ ...style, ...springs }}>
      {renderEditButtons()}
      <div style={{ width: meal.img ? "230px" : "335px" }}>
        <div className="title-price">
          {editable ? (
            <>
              <input
                style={{ width: "150px", fontSize: "15px", textAlign: "right" }}
                value={mealObj.name}
                onChange={(e) =>
                  setMealObj({ ...mealObj, name: e.target.value })
                }
              />
              <input
                style={{ fontSize: "15px", alignSelf: "center", width: "75px" }}
                value={mealObj.price}
                onChange={(e) =>
                  setMealObj({ ...mealObj, price: e.target.value })
                }
              />
            </>
          ) : (
            <>
              <p style={{ width: "180px" }}>{mealObj.name}</p>
              <p
                style={{
                  fontSize: "15px",
                  alignSelf: "center",
                  overflow: "hidden",
                }}
              >
                {mealObj.price}
              </p>
            </>
          )}
        </div>
        {editable ? (
          <div
            style={{ width: mealObj.img ? "200px" : "220px" }}
            className="desc"
          >
            <textarea
              style={{ width: "100%", background: "#444" }}
              value={mealObj.desc}
              onChange={(e) => setMealObj({ ...mealObj, desc: e.target.value })}
            />
            <input
              onChange={(e) => setMealObj({ ...mealObj, img: e.target.value })}
            />
          </div>
        ) : (
          <div
            style={{ width: mealObj.img ? "200px" : "220px" }}
            className="desc"
          >
            {mealObj.desc}
          </div>
        )}
      </div>
      {mealObj.img ? (
        <>
          <img
            src={mealObj.img}
            className="meals-img"
            onClick={handleImageClick}
          />
          {isModalOpen && (
            <div className="modal-meals" onClick={closeModal}>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={mealObj.img}
                  className="modal-img"
                  onClick={closeModal}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </animated.div>
  );
}
